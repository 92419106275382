import React, {useState} from 'react';
import {motion} from 'framer-motion';
import s from "./RegistrationSteps.module.css"
import {NavLink} from "react-router-dom";
import quest from "../../assets/newImg/quest.svg";
import SignUp from "./SignUp/SignUp";
import RegistrationAutentication from "./RegistrationAutentication/RegistrationAutentication";
import CustomModalContactContainer from "../../components/ui/CustomModalContactContainer/CustomModalContactContainer";
import { brandTheme } from '../../config/config';
import euroIcon from "../../assets/newImg/registerPage/euro.svg";
import pointerIcon from "../../assets/newImg/registerPage/pointer.svg";
import bankingIcon from "../../assets/newImg/registerPage/banking.svg";
import fiatIcon from "../../assets/newImg/registerPage/fiat.svg";
import worldVector from "../../assets/newImg/registerPage/world.svg";

const RegistrationSteps = () => {

    const [current, setCurrent] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false)

    const steps = [
        {
            content: <SignUp setCurrent={setCurrent}
                             current={current}
            />
        },
        {
            content: <RegistrationAutentication/>
        },
    ];

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <div className={s.wrapper}>
                <CustomModalContactContainer setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}/>
                <div className={s.login_wrapper_content_left}>
                    <img src={worldVector} className={s.world_vector_bottom_left} alt="" />
                    <div className={s.left_content}>
                        <div className={s.left_content_title}>Privileged and Personalized
                        Banking for the Elite!</div>
                        <div className={s.left_content_description_block}>
                            <div className={s.description_block_icon}><img src={euroIcon} alt=""/></div>
                            <div className={s.description_block_text}>$ USD, € EURO, £ GBP, د.إ AED, CA$ CAD, AU$ AUD, ¥ CYN Account
                            Details with one application.</div>
                        </div>
                        <div className={s.left_content_description_block}>
                            <div className={s.description_block_icon}><img src={pointerIcon} alt=""/></div>
                            <div className={s.description_block_text}>Banking made easier than ever, we do the heavy lifting for you.</div>
                        </div>
                        <div className={s.left_content_description_block}>
                            <div className={s.description_block_icon}><img src={bankingIcon} alt=""/></div>
                            <div className={s.description_block_text}>Multiple Currency Bank Accounts, Debit Cards and Digital Currencies
                            all in one place.</div>
                        </div>
                        <div className={s.left_content_description_block}>
                            <div className={s.description_block_icon}><img src={fiatIcon} alt=""/></div>
                            <div className={s.description_block_text}>Fiat Crypto | Crypto Fiat. Buy / Sell digital currencies with USD,
                                EURO, CAD any regular currency or withdraw funds from your
                                accounts.</div>
                        </div>
                    </div>
                    <div className={s.logo_block}>
                    {brandTheme.brandIcon &&
                        <NavLink to={"/"}>
                            <img src={brandTheme.brandIcon} alt={brandTheme.brandLogoAlt} width={brandTheme.brandLogoWidth} height={brandTheme.brandLogoHeight} />
                        </NavLink>
                    }
                    </div>
                </div>
                <div className={s.login_wrapper_content_right}>
                    <div className={s.quest_block}>
                        <img src={quest} alt="" onClick={() => setIsModalOpen(true)}/>
                    </div>
                    <div>{steps[current].content}</div>
                </div>
            </div>

        </motion.div>
    );
};

export default RegistrationSteps;
